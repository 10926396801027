import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import configUrl from '../ConfigUrl';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TagArticlesPage = () => {
    const { tagId } = useParams();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation(); 

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get(`${configUrl.beBaseUrl}/api/articlestoptags`);
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching articles by tag:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [tagId]);

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div>
            {location.pathname !== '/' && <Header />}

            <div className="tag-articles-container">
                <div 
                    className="back-button" 
                    onClick={handleBack} 
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                </div>

                {loading ? (
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                         <img src="https://www.anjaniwahyudi.com/imgloading.svg" style={{width: '200px', height: '140px'}} alt='imgloading'></img>
                    </div>
                ) : (
                    <div style={{paddingLeft: '10%'}}>
                        <h1>Artikel dengan Tag: #{tagId}</h1>
                        {articles.length === 0 ? (
                            <p>No articles found for this tag.</p>
                        ) : (
                            <ul>
                                {articles.map(article => (
                                    <li key={article.id} style={{alignItems: 'center', marginBottom: '20px' }}>
                                      <div style={{alignItems: 'center', display: 'flex'}}>
                                        <img 
                                            src={`${configUrl.beBaseUrl}${article.image_url}`} 
                                            alt={article.title} 
                                            style={{ width: '160px', height: '100px', marginRight: '10px' }}
                                        />
                                        <Link to={`/articles/${article.id}/${article.slug}`}>
                                            <div>{article.title}</div>
                                        </Link>
                                        </div>
                                        <div style={{marginLeft: '170px'}}>
                                          {article.body.length > 100 ? `${article.body.slice(0, 100)}...` : article.body}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
            {location.pathname !== '/' && <Footer />}
        </div>
    );
};

export default TagArticlesPage;
